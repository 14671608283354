<ng-container *ngIf="profile">
    <!-- Button -->
    <button #userMenuButton="matMenuTrigger" mat-icon-button [matMenuTriggerFor]="userActions">
        <span class="relative">
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        </span>
    </button>

    <mat-menu #userActions="matMenu" class="min-w-72 -mt-2" [xPosition]="'before'">
        <ng-container *transloco="let t; read: 'auth'">
            <!-- User detail -->
            <div class="flex flex-col -mt-2 space-y-4 leading-normal">
                <div class="relative flex flex-col flex-wrap justify-center mb-4">
                    <!-- Profiles -->
                    <div
                        class="profile-herder pt-4 px-4 dark bg-gray-900 flex justify-center items-center h-26 max-w-full"
                    >
                        <span
                            class="text-xl text-medium tracking-tight truncate"
                            matTooltipPosition="above"
                            [matTooltip]="profile.name"
                        >
                            {{ profile.name }}
                        </span>
                    </div>

                    <!-- Avatar -->
                    <avatar class="absolute avatar" [config]="avatarConfig" (ornementClick)="onUpdatePhotoClick()">
                    </avatar>

                    <!-- Identity Information -->
                    <div class="flex flex-col mt-14 px-4 items-center justify-center w-full">
                        <!-- Full Name -->
                        <div
                            class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-xl text-center leading-normal font-medium"
                        >
                            {{ profile.user.firstName }} {{ profile.user.lastName }}
                        </div>

                        <!-- Email -->
                        <div
                            class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary"
                        >
                            {{ profile.user.email }}
                        </div>
                    </div>

                    <!-- Button Change Profile -->
                    <div *ngIf="availableProfiles?.length > 1" class="flex justify-center mt-3">
                        <a
                            mat-stroked-button
                            class="mat-focus-indicator min-w-10 min-h-8 h-8 px-4 leading-6"
                            (click)="onChangeProfile()"
                        >
                            {{ t('PAGES.USER_MENU.CHANGE_PROFILE') }}
                        </a>
                    </div>
                </div>
            </div>

            <mat-divider class="my-2"></mat-divider>

            <!-- Light/Dark Mode -->
            <button *ngIf="(profile.isAdmin || isDevEnv) && config.scheme" mat-menu-item (click)="onToggleScheme()">
                <mat-icon [svgIcon]="'heroicons_outline:' + (config.scheme === 'light' ? 'moon' : 'sun')"></mat-icon>

                <span>
                    {{ 'COMMONS.VERBS.USE' | transloco }}
                    {{
                        'COMMONS.WORDS.' + (config.scheme === 'light' ? 'DARK_MODE' : 'LIGHT_MODE')
                            | transloco
                            | lowercase
                    }}
                </span>
            </button>

            <!-- Manage Account -->
            <div class="flex flex-col">
                <!-- Button settings -->
                <button mat-menu-item (click)="goTo('settings/user')">
                    <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>

                    <span>{{ t('PAGES.USER_MENU.USER_SETTINGS') }}</span>
                </button>

                <!-- Button Logout -->
                <button mat-menu-item (click)="onSignOut()">
                    <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>

                    <span>{{ 'COMMONS.VERBS.LOGOUT' | transloco }}</span>
                </button>
            </div>
        </ng-container>
    </mat-menu>
    <!-- </div> -->
</ng-container>
