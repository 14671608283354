import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Profile, User, IUserConfig } from '@mapuilabs/hv-interfaces';
import { UserService } from '@core/user/user.service';
import { AvatarConfig } from '@shared/modules/avatar/avatar.component';
import { AppConfig, Scheme } from '@core/config/app.config';
import { Subject } from 'rxjs';
import { FuseConfigService } from '@fuse/services/config';
import { first, takeUntil } from 'rxjs/operators';
import { ProfilesCrudService } from '@services/profiles/profiles-crud.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserMenuComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<void> = new Subject<void>();

    public profile: Profile;
    public availableProfiles: Profile[];
    public user: User;

    public config: AppConfig;
    public avatarConfig: AvatarConfig;

    constructor(
        private _router: Router,
        private _profileService: ProfilesCrudService,
        private _fuseConfigService: FuseConfigService,
        public _userService: UserService
    ) {
        this.profile = this._userService.profile;

        // Set user with type guard
        if (this.profile.user instanceof User) {
            this.user = this.profile.user;
        } else {
            throw new Error('Type error: profile.user should be an instance of User');
        }

        // Retrieve available profiles
        this._profileService
            .readByUserId(this.user._id)
            .pipe(first())
            .subscribe({ next: (availableProfiles) => (this.availableProfiles = availableProfiles) });

        // Set avatar config
        this.avatarConfig = {
            profile: this.profile,
            border: true,
            ornement: {
                clickable: true,
                svgIcon: 'heroicons_outline:camera'
            }
        };
    }

    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: (config: AppConfig) => {
                // Store the config
                this.config = config;
            }
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ View Methods
    // -----------------------------------------------------------------------------------------------------

    public onUpdatePhotoClick(): void {
        alert('TODO: implement update photo');
    }

    public onChangeProfile(): void {
        window.location.href = window.location.protocol + '//' + window.location.host + '/change-profile';
    }

    public goTo(url: string): void {
        this._router.navigate([url]).then();
    }

    public onSignOut(): void {
        this._router.navigate(['/sign-out']).then();
    }

    public onToggleScheme(): void {
        const scheme: Scheme = this.config.scheme === 'light' ? 'dark' : 'light';
        this._fuseConfigService.config = { scheme };
        this._userService.saveConfig({ scheme } as IUserConfig).subscribe({
            next: (updatedUser) => {
                this._userService.profile.user = updatedUser;
            },
            error: (err) => {
                console.error(err);
            }
        });
    }

    public get isDevEnv(): boolean {
        return environment.production === false;
    }
}
